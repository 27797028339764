import React from 'react';

import { getPadding, getShadowValFromProps, getTransformerFormFieldFontStyles } from 'xmc-forms/src/util/shared.js';

export const RadioGroup = (props) => {
	const { formId } = props;

	const { options } = props;

	const id = props.id ? props.id.toString().replace(/-/g, '') : '';

	if (options && options.length) {
		return (
			<div
				id={id}
				className={`component radio-group-field ${props.required ? 'required-component' : ''} radio-component radio-group${props.id.replace(
					/-/g,
					'',
				)} form-input-wrapper`}
			>
				<div className={`form-input-wrapper-element lp-flex-container lp-flex-direction-vertical form-input-${formId}`}>
					<span className="global-input-label form-field-label form-group-label ">
						{props.label}
						<span className="global-field-required form-field-required "> *</span>
					</span>
					<div className="radio-group-container">
						{options.map((item) => {
							return (
								<div className="radio-wrapper" key={item.value}>
									<Radio {...props} value={item.value} label={item.label} required={false} labelHide={false} id={id} />
								</div>
							);
						})}
						<input
							data-id={id}
							data-label={props.label}
							data-type={props.type}
							className="radio-group-input"
							data-required={props.required}
							required={props.required}
							// type="hidden"
							name={props.name}
							style={{ display: 'none' }}
						/>
					</div>
					{props.description && <div className="global-input-description form-input-description ">{props.description}</div>}
				</div>
			</div>
		);
	}

	return null;
};

const Radio = (props) => {
	return (
		<label
			className={`radio-field form-input-wrapper-element lp-flex-container lp-flex-direction-horizontal lp-flex-align-center radio${props.id}`}
		>
			<div className="form-input-wrapper-div">
				<input
					className={`global-radio-element global-radio-fill form-input-radio-element form-input-element params-${props.type} `}
					data-type={props.type}
					data-label={props.label}
					data-required={props.required}
					required={props.required}
					// data-validate={props.validate || false}
					type="radio"
					placeholder={props.placeholder}
					disabled={props.disabled}
					name={props.name}
					value={props.value}
					// readOnly={props.readOnly}
				/>
			</div>
			<span className="global-input-label form-field-label  ">{props.label}</span>
		</label>
	);
};

export const generateRadioClass = (component) => {
	return `
        .radio-group${component.id.replace(/-/g, '')} {
            width: 100%; 
            background-color: ${component.background_color};
            display: ${component.hidden ? 'none' : 'block'};
			${getPadding(component)}
        }

        .radio-group${component.id.replace(/-/g, '')} .form-field-label {
            color: ${component.labelFontColour};
            font-size: ${component.labelFontSize}px;
            font-weight: ${component.labelFontWeight};
            font-style: ${component.labelFontStyle};
            text-decoration: ${component.labelTextDecoration};
            display: ${component.labelHide ? 'none' : 'block'};
            ${getTransformerFormFieldFontStyles(component.labelFont)}
        }

        .radio-group${component.id.replace(/-/g, '')} .form-field-label .form-field-required {
            color: ${component.asteriskColor};
        }
			
        .radio-group${component.id.replace(/-/g, '')} .form-input-description, .radio-group${component.id.replace(
					/-/g,
					'',
				)} .form-input-error-message {
            font-style: ${component.descriptionFontStyle};
			font-weight: ${component.descriptionFontWeight};
			text-decoration: ${component.descriptionTextDecoration};
            color: ${component.descriptionFontColour};
            font-family: ${component.descriptionFontFamily};
            font-size: ${component.descriptionFontSize}px;
            margin-top: ${component.descriptionSpacing}px;
            display: ${component.descriptionHide ? 'none' : 'block'};
        }

		.radio-group${component.id.replace(/-/g, '')} .radio-wrapper:not(:last-of-type) {
			margin-bottom: ${component.optionsSpacing}px;
		}

		.radio-group${component.id.replace(/-/g, '')} .form-group-label {
			min-height: ${component.labelWidth}px;
        }

		${generateSingleRadioClass(component)}
    `;
};

export const generateSingleRadioClass = (component) => {
	return `
        .radio${component.id.replace(/-/g, '')} .form-input-element {
            background: ${component.fieldBgColor};
			padding: ${component.fieldPadding}px;
			color: ${component.fieldFontColour};
			font-size: ${component.fieldFontSize}px;
			border-width: ${component.fieldBorderWidth}px;
			border-color: ${component.fieldBorderColour};
			border-radius: ${component.fieldBorderRadius}px;
			font-weight: ${component.fieldFontWeight};
			font-style: ${component.fieldFontStyle};
			text-decoration: ${component.fieldTextDecoration};
            box-shadow: ${getShadowValFromProps(component.box_shadow)};
        }

		.radio${component.id.replace(/-/g, '')} .form-input-element::before {
			border-radius: ${component.fieldBorderRadius - 7}px;
			margin-left: ${0 - component.fieldFontSize / 2}px;
			margin-top: ${0 - component.fieldFontSize / 2}px;
			background-color: ${component.fieldFontColour};
			width: ${component.fieldFontSize - 4}px;
			height: ${component.fieldFontSize - 4}px;
        }
    `;
};

export default RadioGroup;
