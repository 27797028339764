/*
 * KEEP THIS FILE ISOMORPHIC
 * DO NOT IMPORT ANYTHING THAT IS NOT ISOMORPHIC AND WONT WORK ON THE NODE APP
 */

import qs from 'query-string';
import { v4 as uuidv4, v5 as uuidv5 } from 'uuid';
import { resolveProvider } from 'xmc-forms/src/util/imgixService.js';

export const BasicComponentTypes = {
	image: 'image',
	text: 'text',
	// button: 'button',
	spacer: 'spacer',
	social_follow: 'social_follow',
};
export const FieldComponentTypes = {
	submit_button: 'submit_button',
	email_input: 'email_input',
	phone_input: 'phone_input',
	text_input: 'text_input',
	longtext_input: 'longtext_input',
	singleselect_input: 'singleselect_input',
	multiselect_input: 'multiselect_input',
	date_input: 'date_input',
	number_input: 'number_input',
	radio: 'radio',
	checkbox: 'checkbox',
	checkbox_group: 'checkbox_group',
	gdpr: 'gdpr',
	recaptcha: 'recaptcha',
	file_upload: 'file_upload',
};
export const FieldComponentGroupTypes = {
	fields: 'fields',
	checkboxes: 'checkboxes',
	radios: 'radios',
	buttons: 'buttons',
	images: 'images',
	spacers: 'spacers',
	texts: 'texts',
	socials: 'socials',
	other: 'other',
};
export const FieldComponentGroups = {
	[FieldComponentGroupTypes.fields]: [
		FieldComponentTypes.email_input,
		FieldComponentTypes.phone_input,
		FieldComponentTypes.text_input,
		FieldComponentTypes.longtext_input,
		FieldComponentTypes.singleselect_input,
		FieldComponentTypes.multiselect_input,
		FieldComponentTypes.date_input,
		FieldComponentTypes.number_input,
		FieldComponentTypes.file_upload,
	],
	[FieldComponentGroupTypes.other]: [FieldComponentTypes.recaptcha],
	[FieldComponentGroupTypes.checkboxes]: [FieldComponentTypes.checkbox, FieldComponentTypes.checkbox_group, FieldComponentTypes.gdpr],
	[FieldComponentGroupTypes.radios]: [FieldComponentTypes.radio],
	[FieldComponentGroupTypes.buttons]: [FieldComponentTypes.submit_button],
	[FieldComponentGroupTypes.images]: [BasicComponentTypes.image],
	[FieldComponentGroupTypes.spacers]: [BasicComponentTypes.spacer],
	[FieldComponentGroupTypes.socials]: [BasicComponentTypes.social_follow],
	[FieldComponentGroupTypes.texts]: [BasicComponentTypes.text],
};
export const FieldComponentNames = {
	submit_button: 'submit_button',
	email_input: 'email',
	phone_input: 'phone',
	text_input: 'text',
	longtext_input: 'long_text',
	singleselect_input: 'singleselect',
	multiselect_input: 'multiselect',
	date_input: 'date',
	number_input: 'number',
	radio: 'radio',
	checkbox: 'checkbox',
	checkbox_group: 'checkbox_group',
	gdpr: 'gdpr_checkbox',
	recaptcha: 'recaptcha',
	file_upload: 'file_upload',
};
export const AllComponentTypes = {
	...BasicComponentTypes,
	...FieldComponentTypes,
};
export const slotSubstractor = {
	FULL: 1,
	ONE_THIRD: 3,
	ONE_FOURTH: 4,
	HALF: 2,
	TWO_THIRDS: 3 / 2,
};
export const baseFieldStyles = {
	background_color: 'transparent',
	labelFont: 'Arial, Helvetica, sans-serif',
	labelFontSize: 16,
	labelFontColour: '#404040',
	labelPosition: 'column',
	labelTextDecoration: 'none',
	labelFontStyle: 'normal',
	labelFontWeight: 'bold',
	labelWidth: 24,
	asteriskColor: 'rgba(240, 52, 52, 1)',
	placeholderFont: 'Arial, Helvetica, sans-serif',
	placeholderFontSize: 16,
	placeholderFontColour: 'rgba(0,0,0,0.36)',
	placeholderFontWeight: 'normal',
	placeholderTextDecoration: 'none',
	placeholderFontStyle: 'normal',
	fieldFontWeight: 'normal',
	fieldTextDecoration: 'none',
	fieldFontStyle: 'normal',
	fieldFont: 'Arial, Helvetica, sans-serif',
	fieldFontSize: 16,
	fieldFontColour: 'rgba(0, 0, 0, 1)',
	fieldBorderWidth: 1,
	fieldBorderColour: 'rgba(0,0,0,0.42)',
	fieldBorderRadius: 6,
	fieldPadding: 10,
	fieldBgColor: 'rgba(255,255,255,1)',
	descriptionFontColour: 'rgba(0,0,0,0.55)',
	descriptionFontSize: 14,
	descriptionFontFamily: 'Arial, Helvetica, sans-serif',
	descriptionSpacing: 8,
	descriptionHide: false,
	descriptionTextDecoration: 'none',
	descriptionFontStyle: 'normal',
	descriptionFontWeight: 'normal',
};
export const baseRadioStyles = {
	...baseFieldStyles,
	fieldBorderRadius: 17,
	fieldFontColour: 'rgba(0,0,0,1)',
	fieldFontSize: 14,
	fieldPadding: 9,
	fieldBorderWidth: 2,
};
export const baseCheckboxStyles = {
	...baseFieldStyles,
	fieldBorderRadius: 2,
	fieldBorderWidth: 2,
	fieldFontSize: 20,
	fieldPadding: 10,
};
export const baseRecaptchaStyles = {
	background_color: 'transparent',
	alignment: 'center',
	theme: 'light',
	padding: 10,
	multiPadding: {
		allow: false,
		paddingTop: 10,
		paddingRight: 10,
		paddingBottom: 10,
		paddingLeft: 10,
	},
};
export const baseButtonStyles = {
	background_color: 'transparent',
	color: 'rgba(24,112,236,1)',
	text_color: 'rgba(255,255,255,1)',
	alignment: 'center',
	customSize: {
		height: 100,
		width: 50,
	},
	padding: 16,
	multiPadding: {
		allow: false,
		paddingTop: 10,
		paddingRight: 10,
		paddingBottom: 10,
		paddingLeft: 10,
	},
	margin: 16,
	fontFamily: 'Arial, Helvetica, sans-serif',
	fontWeight: 'bold',
	textDecoration: 'none',
	fontStyle: 'normal',
	fontSize: 16,
	width: 'auto',
	borderRadius: 10,
	borderWidth: 0,
	borderColor: '#000000',
};
export const baseBackButtonStyles = {
	background_color: 'transparent',
	color: 'transparent',
	text_color: 'rgba(59,59,59,1)',
	padding: 0,
	alignment: 'center',
	customSize: {
		height: 100,
		width: 50,
	},
	multiPadding: {
		allow: false,
		paddingTop: 10,
		paddingRight: 10,
		paddingBottom: 10,
		paddingLeft: 10,
	},
	margin: 16,
	fontFamily: 'Arial, Helvetica, sans-serif',
	fontWeight: 'bold',
	textDecoration: 'none',
	fontStyle: 'normal',
	fontSize: 16,
	width: 'auto',
	borderRadius: 10,
	borderWidth: 0,
	borderColor: '#000000',
};
export const baseImageStyles = {
	background_color: 'transparent',
	imageBorderRadius: 0,
	imageBorderWidth: 0,
	imageBorderColor: '#000000',
	padding: 0,
	multiPadding: {
		allow: false,
		paddingTop: 0,
		paddingRight: 0,
		paddingBottom: 0,
		paddingLeft: 0,
	},
	alignment: 'left',
};
export const baseTextStyles = {
	background_color: 'transparent',
	textBorderRadius: 0,
	textBorderWidth: 0,
	textBorderColor: '#000000',
	padding: 10,
	multiPadding: {
		allow: false,
		paddingTop: 10,
		paddingRight: 10,
		paddingBottom: 10,
		paddingLeft: 10,
	},
};
export const baseSocialStyles = {
	background_color: 'transparent',
	spacing: 2,
	alignment: 'center',
};
export const baseSpacerStyles = {
	borderWidth: 0,
	borderRadius: 0,
	borderColor: '#000000',
	height: 40,
	background_color: 'transparent',
};
const formInputDefaults = {
	id: '',
	type: '',
	name: '',
	background_color: 'transparent',
	...baseFieldStyles,
	width: 100,
	label: '',
	required: false,
	hidden: false,
	prefill: undefined,
	hiddenField: false,
	placeholder: '',
	disabled: false,
	value: '',
	readOnly: false,
	description: 'Help text…',
	optionsSpacing: 10,
	padding: 16,
	multiPadding: {
		allow: false,
		paddingTop: 16,
		paddingRight: 16,
		paddingBottom: 16,
		paddingLeft: 16,
	},
	pattern: '',
	mobileProps: {},
};
export const FormSuccessActions = {
	none: 'none',
	message: 'message',
	redirectUrl: 'redirectUrl',
};
export const FormFailActions = {
	message: 'message',
};
export const validationMessages = {
	required: 'This field is required',
	email: 'Email address must follow the format user@example.com',
	phone: 'Phone number must follow the format +(country_code)(phone_number)',
	recaptcha: 'Validate using the reCAPTCHA',
	uploadFileType: 'File type not supported',
	uploadFileSize: 'Max file size exceeded',
	uploadFileCount: 'Max number of files exceeded',
	uploadFileGenericError: 'File did not upload',
};
export const googleFontBaseUrl = 'https://fonts.googleapis.com/css?family=';
export const googleFontEmbed = (fonts) => `<link href="${googleFontBaseUrl}${fonts}&amp;subset=greek,greek-ext" rel="stylesheet">`;
export const BaseSize = 1024;
export const UnitsConfig = {
	Bytes: { base: 1, enabled: false },
	KB: { base: BaseSize, enabled: true },
	MB: { base: BaseSize * BaseSize, enabled: true },
};
export const SpecialComponentTypes = {
	fieldPlaceholder: 'fieldPlaceholder',
};
export const ComponentDefaults = {
	fieldPlaceholder: {
		id: '',
		type: SpecialComponentTypes.fieldPlaceholder,
	},
	recaptcha: {
		...baseRecaptchaStyles,
		id: '',
		type: AllComponentTypes.recaptcha,
		siteKey: '',
		mobileProps: {},
	},
	radio: {
		...formInputDefaults,
		...baseRadioStyles,
		hasCustomValues: false,
		type: AllComponentTypes.radio,
		label: 'Radio button',
		name: FieldComponentNames.radio,
		placeholder: 'Radio button',
		labelWidth: 30,
		options: [
			{ value: 'Option 1', label: 'Option 1' },
			{ value: 'Option 2', label: 'Option 2' },
			{ value: 'Option 3', label: 'Option 3' },
		],
	},
	checkbox: {
		...formInputDefaults,
		...baseCheckboxStyles,
		type: AllComponentTypes.checkbox,
		labelWidth: 32,
		label: 'Checkbox',
		name: FieldComponentNames.checkbox,
		placeholder: 'Checkbox',
	},
	gdpr: {
		...formInputDefaults,
		type: AllComponentTypes.gdpr,
		label: 'I accept …',
		name: FieldComponentNames.gdpr,
		placeholder: 'Checkbox',
		legalText: '',
		legalLabel: 'I accept terms',
		required: true,
	},
	checkbox_group: {
		...formInputDefaults,
		...baseCheckboxStyles,
		hasCustomValues: false,
		type: AllComponentTypes.checkbox_group,
		labelWidth: 32,
		label: 'Checkbox Group',
		name: FieldComponentNames.checkbox_group,
		optionsLimit: 0,
		placeholder: 'Checkbox',
		options: [
			{ value: 'Option 1', label: 'Option 1' },
			{ value: 'Option 2', label: 'Option 2' },
			{ value: 'Option 3', label: 'Option 3' },
		],
	},
	phone_input: {
		...formInputDefaults,
		type: AllComponentTypes.phone_input,
		label: 'Phone',
		name: FieldComponentNames.phone_input,
		placeholder: '',
	},
	email_input: {
		...formInputDefaults,
		type: AllComponentTypes.email_input,
		label: 'Email',
		name: FieldComponentNames.email_input,
		placeholder: 'Enter email',
	},
	text_input: {
		...formInputDefaults,
		maxLength: 200,
		type: AllComponentTypes.text_input,
		label: 'Short text',
		name: FieldComponentNames.text_input,
		placeholder: 'Enter text',
	},
	longtext_input: {
		...formInputDefaults,
		maxLength: 1000,
		type: AllComponentTypes.longtext_input,
		label: 'Long text',
		name: FieldComponentNames.longtext_input,
		placeholder: 'Enter text',
	},
	singleselect_input: {
		...formInputDefaults,
		hasCustomValues: false,
		type: AllComponentTypes.singleselect_input,
		label: 'Select',
		name: FieldComponentNames.singleselect_input,
		placeholder: 'Select option',
		options: [
			{ value: 'Option 1', label: 'Option 1' },
			{ value: 'Option 2', label: 'Option 2' },
			{ value: 'Option 3', label: 'Option 3' },
		],
	},
	multiselect_input: {
		...formInputDefaults,
		hasCustomValues: false,
		type: AllComponentTypes.multiselect_input,
		label: 'Select',
		name: FieldComponentNames.multiselect_input,
		placeholder: 'Select options',
		optionsLimit: 0,
		options: [
			{ value: 'Option 1', label: 'Option 1' },
			{ value: 'Option 2', label: 'Option 2' },
			{ value: 'Option 3', label: 'Option 3' },
		],
	},
	date_input: {
		...formInputDefaults,
		type: AllComponentTypes.date_input,
		label: 'Date',
		name: FieldComponentNames.date_input,
		placeholder: '',
	},
	number_input: {
		...formInputDefaults,
		type: AllComponentTypes.number_input,
		label: 'Number',
		name: FieldComponentNames.number_input,
		placeholder: 'Enter number',
	},
	file_upload: {
		...formInputDefaults,
		includeMetadata: false,
		descriptionFontStyle: 'normal',
		type: AllComponentTypes.file_upload,
		label: 'Upload files',
		name: FieldComponentNames.file_upload,
		fileSizeLimit: 5242880,
		fileSizeLimitUnit: 'MB',
		description: 'You can upload a maximum of [count] files, each with a size limit of [size]',
		maxFiles: 1,
		acceptedFileTypes: [],
	},
	image: {
		id: '',
		type: 'image',
		resized: false,
		resizeWidth: null,
		resizeHeight: null,
		originalWidth: 600,
		currentSrc: '',
		originalSrc: '',
		link_url: '',
		src: '',
		alt: '',
		...baseImageStyles,
		mobileProps: {},
	},
	text: {
		id: '',
		type: 'text',
		text: '',
		...baseTextStyles,
		mobileProps: {},
	},
	submit_button: {
		id: '',
		type: AllComponentTypes.submit_button,
		noLink: true,
		...baseButtonStyles,
		text: 'Submit',
		url: '',
		newWindow: true,
		back_button: {
			text: 'Back',
			...baseBackButtonStyles,
			newWindow: true,
		},
		mobileProps: {},
	},
	social_follow: {
		id: '',
		type: 'social_follow',
		...baseSocialStyles,
		style: 'default',
		facebook: '',
		twitter: '',
		instagram: '',
		pinterest: null,
		googleplus: null,
		youtube: null,
		linkedin: null,
		tumblr: null,
		deprecatedUsed: [],
		mobileProps: {},
	},
	spacer: {
		id: '',
		type: AllComponentTypes.spacer,
		...baseSpacerStyles,
		mobileProps: {},
	},
};
export const RowDefaultProperties = {
	background_image: '',
	background_image_type: '',
	background_repeat: 'no-repeat',
	background_position: 'initial',
	borderWidth: 0,
	borderColor: '#000000',
	borderRadius: 0,
	padding: 0,
	multiPadding: {
		allow: false,
		paddingTop: 0,
		paddingRight: 0,
		paddingBottom: 0,
		paddingLeft: 0,
	},
	pageIndex: 0,
	bg_color: 'transparent',
	hidden: false,
	responsive: false,
	behavior: 'NORMAL',
	slot_spacing_side: 0,
	slot_spacing_center: 0,
	mobileProps: {},
};
export const SlotDefaultProperties = {
	background_color: 'transparent',
	contentBgColor: 'transparent',
	borderWidth: 0,
	borderColor: '#000000',
	borderRadius: 0,
	padding: 0,
	hidden: false,
	id: '',
	multiPadding: {
		allow: false,
		paddingTop: 0,
		paddingRight: 0,
		paddingBottom: 0,
		paddingLeft: 0,
	},
	vertical_align: 'top',
	components: [],
	mobileProps: {},
};

export const childrenExcluder = (key, value) => {
	if (key === 'children') {
		return undefined;
	}
	return value;
};
export const getElementById = (elementArray, elementId) => {
	for (let i = 0; i < elementArray.length; i++) {
		if (elementArray[i].id === elementId) {
			return elementArray[i];
		}
	}
	return null;
};
export const getCorrectedHtml = (html) => {
	const div = document.createElement('div');
	div.innerHTML = html;

	const children = [...div.getElementsByTagName('*')];
	if (children && children.length) {
		children.forEach((element) => {
			const font = element.style.fontFamily;
			if (!font) {
				element.style.fontFamily = 'inherit';
			}
		});
	}

	return div.innerHTML;
};
export const getDesignerAlignment = (alignment) => {
	switch (alignment) {
		case 'top':
			return 'flex-start';

		case 'left':
			return 'flex-start';

		case 'middle':
			return 'center';

		case 'center':
			return 'center';

		case 'bottom':
			return 'flex-end';

		case 'right':
			return 'flex-end';

		default:
			return 'flex-start';
	}
};
export const getTransformerFormFieldFontStyles = (font) => {
	if (font && font.includes(':')) {
		const fontArray = font.split(':');

		let returnFont = `font-family: ${fontArray[0]};`;

		if (fontArray[1] === 'italic') {
			returnFont = `
				${returnFont};
				font-style: ${fontArray[1]};
			`;
		} else if (fontArray[1].length === 3) {
			returnFont = `
				${returnFont};
				font-weight: ${fontArray[1]};
			`;
		} else if (fontArray[1].includes('italic') && fontArray[1].length > 3) {
			returnFont = `
				${returnFont};
				font-weight: ${fontArray[1].substr(0, 3)};
				font-style: ${fontArray[1].substr(3, fontArray[1].length - 1)};
			`;
		}

		return returnFont;
	}

	return `font-family: ${font};`;
};
export const getBoxShadowValue = (offsetx, offsety, blur, spread, color) => `${offsetx}px ${offsety}px ${blur}px ${spread}px ${color}`;
export const getShadowValFromProps = (prop) => {
	if (prop) {
		const { offsetx, offsety, blur, spread, color } = prop;
		return getBoxShadowValue(offsetx, offsety, blur, spread, color);
	} else return `none`;
};

export function convertSize(value, from, to) {
	return value * (UnitsConfig[from].base / UnitsConfig[to].base);
}

export const generateGuid = (name, namespace) => {
	if (namespace && name) {
		return uuidv5(name, namespace);
	}
	return uuidv4();
}; //function to calculate image width inside slot, accounting for resizing, slot width
export const calculateImageWidthInSlot = (resizeWidth, slotWidth) => {
	let imageWidth = resizeWidth || slotWidth;

	//to check if resized image is larger than slot width and padding
	if (resizeWidth && resizeWidth > slotWidth) {
		imageWidth = slotWidth;
	}

	return imageWidth;
};
export const getMaxConstraints = (props, property) => {
	if (props.multiPadding && props.multiPadding.allow) {
		const paddings = props.multiPadding.paddingLeft + props.multiPadding.paddingRight;
		return paddings ? property - paddings : property;
	}

	return props.padding ? property - props.padding * 2 : property;
};
export const validateUrl = (value) => {
	if (!value || value == '') {
		return false;
	}

	return true;

	// let pattern = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/g;
	// return pattern.test(value.trim());
};
export const generateImageContentUrlByProvider = (provider, src, type, width, retina = false, imgixPrefix) => {
	return provider.getUrlWithWidth(src, type, width, retina, imgixPrefix);
};

export const generateImageContentUrl = (src, type, width, retina = false, imgixPrefix) => {
	const provider = resolveProvider();

	return generateImageContentUrlByProvider(provider, src, type, width, retina, imgixPrefix);
};
export const getEntityId = (config) => {
	return config.query?.entityId ? config.query.entityId : qs.parse(location.search).entityId;
};
export const getId = (id) => id?.toString()?.replace(/-/g, '') || '';

export const getPadding = (component, asObject = false) => {
	const { padding, multiPadding } = component;

	if (multiPadding && multiPadding.allow) {
		if (asObject) {
			return {
				paddingTop: parseInt(multiPadding.paddingTop, 10),
				paddingRight: parseInt(multiPadding.paddingRight, 10),
				paddingBottom: parseInt(multiPadding.paddingBottom, 10),
				paddingLeft: parseInt(multiPadding.paddingLeft, 10),
			};
		}
		return `padding-top: ${parseInt(multiPadding.paddingTop, 10)}px;
            padding-right: ${parseInt(multiPadding.paddingRight, 10)}px;
            padding-bottom: ${parseInt(multiPadding.paddingBottom, 10)}px;
            padding-left: ${parseInt(multiPadding.paddingLeft, 10)}px;`;
	}

	if (asObject) {
		return {
			padding: parseInt(padding, 10),
		};
	}
	return `padding: ${parseInt(padding, 10)}px;`;
};
