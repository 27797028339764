type FormComponent = {
	text_color: string;
	color: string;
};

/**
 * Converts a color to RGB.
 * @param {string} color - The color code in hex or rgb/rgba format.
 * @returns {number[]} - The RGB representation.
 */
const colorToRgb = (color: string): number[] => {
	if (color.startsWith('#')) {
		const bigint = parseInt(color.slice(1), 16);
		return [(bigint >> 16) & 255, (bigint >> 8) & 255, bigint & 255];
	} else if (color.startsWith('rgb')) {
		const rgbValues = color.match(/\d+/g)?.map(Number);
		if (rgbValues && rgbValues.length >= 3) {
			return [rgbValues[0], rgbValues[1], rgbValues[2]];
		}
	}
	throw new Error('Invalid color format');
};

/**
 * Calculates the relative luminance of a color.
 * @param {number[]} rgb - The RGB representation of the color.
 * @returns {number} - The relative luminance.
 */
const getLuminance = (rgb: number[]): number => {
	const [r, g, b] = rgb.map((value) => {
		const channel = value / 255;
		return channel <= 0.03928 ? channel / 12.92 : Math.pow((channel + 0.055) / 1.055, 2.4);
	});
	return 0.2126 * r + 0.7152 * g + 0.0722 * b;
};

/**
 * Calculates the contrast ratio between two colors.
 * @param {string} color1 - The first color in hex or rgb/rgba format.
 * @param {string} color2 - The second color in hex or rgb/rgba format.
 * @returns {number} - The contrast ratio.
 */
const getContrastRatio = (color1: string, color2: string): number => {
	const luminance1 = getLuminance(colorToRgb(color1));
	const luminance2 = getLuminance(colorToRgb(color2));
	return (Math.max(luminance1, luminance2) + 0.05) / (Math.min(luminance1, luminance2) + 0.05);
};

/**
 * Checks if the color contrast between the component's text and background is sufficient.
 * @param {FormComponent} component - The component object.
 * @returns {boolean} - True if the contrast is sufficient, otherwise false.
 */
export const checkColorContrast = (component: FormComponent): boolean => {
	const textColor = component.text_color || '#000000'; // Default to black if not specified
	const backgroundColor = component.color || '#FFFFFF'; // Default to white if not specified
	const contrastRatio = getContrastRatio(textColor, backgroundColor);
	return contrastRatio >= 4.5; // WCAG AA standard for normal text
};
