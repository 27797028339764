import { act } from 'react';

import axios from 'axios';
import config from 'config/config';
import { ErrorMessages } from 'config/messages';
import { produce } from 'immer';
import Moment from 'moment';
import { create } from 'zustand';

import {
	deleteCustomElement,
	getCustomElement,
	getCustomElements,
	getCustomElementsFromRsp,
	postCustomElement,
} from '@/util/apiUtils/customElements';
import { deleteCustomRow, getCustomRow, getCustomRows, getCustomRowsFromRsp, postCustomRow } from '@/util/apiUtils/customRows';
import { getAllBlueprints, saveBlueprint, updateBlueprint } from '@/util/apiUtils/entities';
import {
	createHtmlImage,
	extractAndMergeAllDraftsFromResponses,
	getAutosaveDrafts,
	getDrafts,
	postAutosaveDraft,
	postDraft,
	sortDrafts,
} from '@/util/apiUtils/template';
import { getUser } from '@/util/auth/auth';
import {
	checkBoolean,
	extractRealElementProps,
	extractRealRowProps,
	findRow,
	isTemplateMgm,
	simpleObjectEquals,
	validateEmail,
} from '@/util/helper';
import { ApiAuthorisationModes, NotificationTypes, toggleConstants } from '@/util/resources';
import { generateGuid } from '@/util/shared';

import useContentStore from './ContentStore';
import { showNotification } from './NotificationStore';

const DefaultState = {
	scale: 1,
	settings: {
		toggledElementSettingsAddress: null,
		activeElement: null,
		helper: {},
	},
	custom_rows: {
		rows: [],
		row_to_save: null,
	},
	custom_elements: {
		elements: [],
		element_to_save: null,
	},
	send_test: {
		emails: [],
		saved_recipients: [],
		error: null,
	},
	versions: {
		draftsMetadata: [],
		latestDraft: {
			json: null,
		},
	},
	allowProdSave: false,
	options: {},
	showHelpSection: false,
	accountCustomSettings: [],
};

const useLeftSidebarStore = create((set, get) => ({
	...DefaultState,
	getDrafts: async () => {
		try {
			const [draftsRsp, autosaveDraftsRsp] = await axios.all([getDrafts(), getAutosaveDrafts()]);

			const allDraftsMetadata = extractAndMergeAllDraftsFromResponses([
				draftsRsp,
				{ data: { Data: autosaveDraftsRsp.data ? [autosaveDraftsRsp.data] : [] } },
			]);

			get().loadDraftsMetadata(allDraftsMetadata);
		} catch (e) {
			console.log(e);
			showNotification({ type: NotificationTypes.ERROR, text: 'Failed to get drafts' });
		}
	},
	saveNewDraft: async (template, autosave = false, latestDraft, successCb, errorCb, warningCb, html = null, imageData) => {
		//html here is just for the moosend api saves

		let decoratedDraftMetadata;

		if (template) {
			try {
				let id = generateGuid();
				let dateSaved = Moment().format('X');
				let title = Moment().format('MMMM Do YYYY, h:mm:ss a');

				let fromTemplateId = {};

				if (isTemplateMgm()) {
					fromTemplateId = { fromTemplateId: template.json.templateId || 'no-template' };
				}

				const fromUser = getUser().name;

				let decoratedDraft = {
					...template,
					autosave: false,
					category: config.template_market.market_default_category_name,
					id,
					dateSaved,
					title,
					fromUser,
					...fromTemplateId,
				};

				if (imageData) {
					const editorOverlay = document.querySelector('#initial-overlay');
					const messageDiv = editorOverlay && editorOverlay.querySelector('#initial-error');
					if (messageDiv) messageDiv.innerHTML = 'Saving Content…';
					if (editorOverlay) editorOverlay.style.display = 'flex';

					const imageRsp = await createHtmlImage(imageData.content, imageData.structureWidth);

					if (editorOverlay) editorOverlay.style.display = 'none';

					if (imageRsp && imageRsp.data) {
						decoratedDraft = {
							...decoratedDraft,
							generatedImage: imageRsp.data,
						};
					}
				}

				let postDraftFunc = postDraft;

				if (autosave) {
					decoratedDraft.autosave = true;
					postDraftFunc = postAutosaveDraft; //change function if it is an autosave
				}

				if (
					!html &&
					simpleObjectEquals({ ...decoratedDraft.json, ...decoratedDraft.mobile }, { ...latestDraft.json, ...latestDraft.mobile })
				) {
					if (warningCb) warningCb();
					return;
				}

				let draftRsp = await postDraftFunc(decoratedDraft);

				decoratedDraftMetadata = { ...decoratedDraft, MetadataVersionId: draftRsp.data.MetadataVersionId };

				if (isTemplateMgm()) {
					if (successCb) successCb();
					return;
				}

				if (html) {
					// if landing page config and html are present save through moosend api as well

					let blueprintResponse;
					const blueprints = await getAllBlueprints();

					const jsonContent = {
						content: decoratedDraft.json,
						generalSettings: decoratedDraft.generalSettings,
					};

					const structureWidth = decoratedDraft.json.structureWidth;

					if (!blueprints.data.Blueprint) {
						blueprintResponse = await saveBlueprint({ html, json: JSON.stringify(jsonContent) }, structureWidth);
					} else {
						blueprintResponse = await updateBlueprint({ html, json: JSON.stringify(jsonContent) }, structureWidth);
					}

					if (blueprintResponse.data.Error) {
						throw blueprintResponse;
					} else {
						if (successCb) successCb();
					}
				} else {
					if (successCb) successCb();
				}

				get().getDrafts();
				get().setDraftAsContentJson(decoratedDraftMetadata);
			} catch (error) {
				console.error(error, true);
				if (errorCb) errorCb(error);
			}
		}
	},

	saveCustomRow: async (row, title, id, order, AuthorisationMode = ApiAuthorisationModes.Private, successCb, errorCb) => {
		let customRowTitle = title ? title : '<no title>';
		row = { ...row, id: generateGuid() };

		try {
			let customRowRsp = await postCustomRow(customRowTitle, row, id, order, AuthorisationMode);

			if (successCb) successCb(customRowRsp.data);
		} catch (error) {
			console.error(error, true);
			if (errorCb) errorCb(error);
		}
	},
	removeCustomRow: async (id, successCb, errorCb) => {
		try {
			const deleteRowRsp = await deleteCustomRow(id);
			if (successCb) successCb(deleteRowRsp.data);
		} catch (error) {
			console.error(error, true);
			if (errorCb) errorCb(error);
		}
	},
	getCustomRows: async () => {
		try {
			const customRowsRsp = await getCustomRows();
			if (customRowsRsp && customRowsRsp.data) {
				get().loadCustomRows(getCustomRowsFromRsp(customRowsRsp));
			}
		} catch (error) {
			console.error(error, true);
		}
	},
	getCustomRowData: async (row, successCb, errorCb) => {
		try {
			const response = await getCustomRow(row);
			if (response.data && response.data.Content) {
				if (successCb) successCb(response.data.Content.Data);
			}
		} catch (error) {
			console.error(error, true);
			if (errorCb) errorCb(error);
		}
	},
	saveCustomElement: async (element, title, id, order, AuthorisationMode = ApiAuthorisationModes.Private, successCb, errorCb) => {
		let customElementTitle = title ? title : '<no title>';
		element = { ...element, id: generateGuid() };

		try {
			let customElementRsp = await postCustomElement(customElementTitle, element, id, order, AuthorisationMode);

			if (successCb) successCb(customElementRsp.data);
		} catch (error) {
			console.error(error, true);
			if (errorCb) errorCb(error);
		}
	},
	removeCustomElement: async (id, successCb, errorCb) => {
		try {
			const deleteElementRsp = await deleteCustomElement(id);
			if (successCb) successCb(deleteElementRsp.data);
		} catch (error) {
			console.error(error, true);
			if (errorCb) errorCb(error);
		}
	},
	getCustomElements: async () => {
		try {
			const customElementsRsp = await getCustomElements();
			if (customElementsRsp && customElementsRsp.data) {
				get().loadCustomElements(getCustomElementsFromRsp(customElementsRsp));
			}
		} catch (error) {
			console.error(error, true);
		}
	},
	getCustomElementData: async (element, successCb, errorCb) => {
		try {
			const response = await getCustomElement(element);

			if (response.data && response.data.Content) {
				const componentData = { ...JSON.parse(response.data.Content.Data), uniqueId: generateGuid() };
				if (successCb) successCb(componentData);
			}
		} catch (error) {
			console.error(error, true);
			if (errorCb) errorCb(error);
		}
	},

	setDraftAsContentJson: (draft) => {
		set(
			produce((state) => {
				state.versions.latestDraft = draft;
			}),
		);
	},
	loadDraftsMetadata: (draftsMetadata) => {
		let regulatedDrafts = regulateDrafts(draftsMetadata);
		set(
			produce((state) => {
				state.versions.draftsMetadata = regulatedDrafts;
			}),
		);
	},
	toggleElementSettings: ({ address }) => {
		const contentStore = useContentStore.getState();
		const row = findRow(contentStore.content.rows, address.rowId).row;

		const settings = {
			toggledElementSettingsAddress: address,
			activeElement: { ...row.slots[address.slot].components[address.component] },
			helper: contentStore.helper,
		};

		set(() => ({ settings }));
	},
	toggleRowSettings: (rowId) => {
		const contentStore = useContentStore.getState();
		const row = findRow(contentStore.content.rows, rowId).row;

		const settings = {
			toggledElementSettingsAddress: rowId,
			activeElement: row,
			helper: contentStore.helper,
		};

		set(() => ({ settings }));
	},
	toggleSummaryPageSettings: () => {
		set(
			produce((state) => {
				state.settings.toggledElementSettingsAddress = 'summary_page';
				state.settings.helper = useContentStore.getState().helper;
				state.activeElement = useContentStore.getState().content;
			}),
		);
	},
	toggleGeneralSettings: () => {
		set(
			produce((state) => {
				state.settings.toggledElementSettingsAddress = null;
				state.settings.activeElement = useContentStore.getState().content;
				state.settings.helper.clicked_row = null;
				state.settings.helper.clicked_element = null;
			}),
		);
	},
	unsetClickedRow: () => {
		set(
			produce((state) => {
				state.settings.toggledElementSettingsAddress = null;
				state.settings.activeElement = useContentStore.getState().content;
				state.settings.helper.clicked_row = null;
				state.settings.helper.clicked_element = null;
			}),
		);
	},
	moveComponent: ({ fromAddress, toAddress }) => {
		if (get().settings.toggledElementSettingsAddress && simpleObjectEquals(get().settings.toggledElementSettingsAddress, fromAddress)) {
			get().toggleElementSettings({ address: toAddress });
		}
	},
	updateElementSettings: ({ address }) => {
		const contentStore = useContentStore.getState();

		const row = findRow(contentStore.content.rows, address.rowId).row;

		if (simpleObjectEquals(contentStore.helper.clicked_element, address)) {
			set(
				produce((state) => {
					state.settings.activeElement = { ...row.slots[address.slot].components[address.component] };
					state.settings.helper = contentStore.helper;
				}),
			);
		}
	},
	updateContentSettings: () => {
		const contentStore = useContentStore.getState();

		set(
			produce((state) => {
				state.settings.toggledElementSettingsAddress = null;
				state.settings.activeElement = contentStore.content;
				state.settings.helper = contentStore.helper;
			}),
		);
	},
	updateRowSettings: (rowId) => {
		const contentStore = useContentStore.getState();

		const row = findRow(contentStore.content.rows, rowId).row;

		set(
			produce((state) => {
				state.settings.toggledElementSettingsAddress = rowId;
				state.settings.activeElement = row;
				state.settings.helper = useContentStore.getState().helper;
			}),
		);
	},
	updateContentHelper: () => {
		set(
			produce((state) => {
				state.settings.helper = useContentStore.getState().helper;
			}),
		);
	},
	addEmailForSendTest: (email) => {
		if (get().send_test.emails.length === config.send_test_limit) {
			showNotification({
				type: NotificationTypes.ERROR,
				text: ErrorMessages.SEND_TEST_LIMIT.replace('{send_test_limit}', config.send_test_limit.toString()),
			});
		} else if (get().send_test.emails.includes(email)) {
			showNotification({ type: NotificationTypes.ERROR, text: ErrorMessages.SEND_TEST_INCLUDED });
		} else if (!validateEmail(email)) {
			showNotification({ type: NotificationTypes.ERROR, text: ErrorMessages.SEND_TEST_INVALID });
		} else {
			set(
				produce((state) => {
					state.send_test.emails.push(email);
					state.send_test.error = null;
					state.send_test.success = null;
				}),
			);
		}
	},
	removeEmailFromSendTest: (index) => {
		set(
			produce((state) => {
				state.send_test.emails.splice(index, 1);
				state.send_test.error = null;
				state.send_test.success = null;
			}),
		);
	},
	undoContentStoreState: () => {
		// TODO
		// this.waitFor(UndoStore);
		let address = get().settings.toggledElementSettingsAddress;

		let component = null;

		if (address && address.component) {
			// meaning element settings are open
			component = findRow(useContentStore.getState().content.rows, address.rowId).row.slots[address.slot].components[address.component];
			if (component) get().toggleElementSettings({ address });
		} else if (address && typeof address === 'string') {
			// meaning row settings are open
			component = findRow(useContentStore.getState().content.rows, address).row;
			if (component) get().toggleRowSettings(address);
		}

		// !component && get().toggledItem === toggleConstants.SETTINGS && get().toggleGeneralSettings();
	},
	removeRow: () => {
		get().toggleMenuItem(toggleConstants.STRUCTURE);
	},
	removeComponent: () => {
		get().toggleMenuItem(toggleConstants.ELEMENTS); //Why was this STRUCTURE
	},
	resetStore: () => act(() => set(() => DefaultState)),
	setImage: ({ type, details }) => {
		switch (type) {
			case 'element':
				get().updateElementSettings({ address: details });
				break;

			case 'video':
				get().updateElementSettings({ address: details });
				break;

			case 'promotion':
				get().updateContentSettings();
				break;

			case 'customLogo':
				get().updateContentSettings();
				break;

			case 'row':
				get().updateRowSettings(details);
				break;

			case 'row-wide':
				get().updateRowSettings(details);
				break;

			case 'background':
				get().updateContentSettings();
				break;

			default:
				break;
		}
	},
	setCustomRowToSave: (row) => {
		let realRow = extractRealRowProps(row);
		set(
			produce((state) => {
				state.custom_rows.row_to_save = realRow;
			}),
		);
	},
	setCustomElementToSave: (element) => {
		let realElement = extractRealElementProps(element);
		set(
			produce((state) => {
				state.custom_elements.element_to_save = realElement;
			}),
		);
	},
	loadCustomRows: (rows) => {
		const publicRows = rows.filter((item) => {
			return item.authorisationMode === ApiAuthorisationModes.Organization;
		});

		const privateRows = rows.filter((item) => {
			return item.authorisationMode === ApiAuthorisationModes.Private;
		});

		const sortedPublicRows = publicRows.sort((a, b) => {
			return a.order - b.order;
		});

		set(
			produce((state) => {
				state.custom_rows.rows = [...sortedPublicRows, ...privateRows];
			}),
		);
	},
	loadCustomElements: (elements) => {
		const publicElements = elements.filter((item) => {
			return item.authorisationMode === ApiAuthorisationModes.Organization;
		});

		const privateElements = elements.filter((item) => {
			return item.authorisationMode === ApiAuthorisationModes.Private;
		});

		const sortedPublicElements = publicElements.sort((a, b) => {
			return a.order - b.order;
		});

		set(
			produce((state) => {
				state.custom_elements.elements = [...sortedPublicElements, ...privateElements];
			}),
		);
	},
	toggleProdSave: () => {
		set(() => ({ allowProdSave: !get().allowProdSave }));
	},
	setOptions: (options) => {
		set(() => ({ options: { ...get().options, ...options } }));
	},
	setData: (data) => {
		set(() => ({ ...get(), ...data }));
	},
	setShowHelpSection(showHelpSection) {
		set(() => ({ showHelpSection }));
	},
	setAccountCustomSettings(data) {
		set(() => ({ accountCustomSettings: data }));
	},
}));

export default useLeftSidebarStore;

//this removes the extra autosaves according to config limit
export function regulateDrafts(drafts) {
	let sortedDrafts = sortDrafts(drafts);
	let regulatedDrafts = [];

	sortedDrafts.forEach((draft) => {
		if (!checkBoolean(draft.autosave)) {
			regulatedDrafts.push(draft);
		} else if (
			checkBoolean(draft.autosave) &&
			regulatedDrafts.filter((regulatedDraft) => checkBoolean(regulatedDraft.autosave)).length < config.autosave_count
		) {
			regulatedDrafts.push(draft);
		}
	});

	return regulatedDrafts;
}
