import { useMemo } from 'react';

import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	IconButton,
	Popover,
	PopoverArrow,
	PopoverBody,
	PopoverCloseButton,
	PopoverContent,
	PopoverHeader,
	PopoverTrigger,
	Tag,
	Text,
	Tooltip,
} from '@chakra-ui/react';
import { mdiHuman, mdiTarget } from '@mdi/js';

import useContentStore from '@/stores/ContentStore';

import { scrollToElementById } from '@/util/helper';

import { Icon } from '@/components/gui/shared/Icon';

export const ACCESSIBILITY_ERROR_CATEGORIES = {
	ALT_TEXT: 'Alt text',
	LABEL: 'Label',
	DESCRIPTIVE_TEXT: 'Descriptive text',
	CONTRAST: 'Contrast',
};

type AccessibilityError = {
	page: number;
	type: string;
	id: string;
	errorType: string;
};

const getTagProps = (type: string, count: number) => {
	if (count === 0) {
		return {
			colorScheme: 'success',
			children: 'All clear',
			'data-testid': `accessibility-list-${type.toLowerCase().replace(' ', '-')}-tag`,
		};
	}
	if (type === ACCESSIBILITY_ERROR_CATEGORIES.CONTRAST || type === ACCESSIBILITY_ERROR_CATEGORIES.LABEL) {
		return { colorScheme: 'warning', children: count, 'data-testid': `accessibility-list-${type.toLowerCase().replace(' ', '-')}-tag` };
	}
	return { colorScheme: 'neutral', children: count, 'data-testid': `accessibility-list-${type.toLowerCase().replace(' ', '-')}-tag` };
};

const ErrorIndicatorWithAccordion = ({ errorType, errorCounts, renderAccessibilityErrors }) => {
	const hasErrors = errorCounts[errorType] > 0;

	return (
		<AccordionItem>
			{!hasErrors ? (
				<Box display="flex" p="4" pr="8">
					<Box flex="1" textAlign="left">
						{errorType}
					</Box>
					<Tag justifyContent="center" {...getTagProps(errorType, errorCounts[errorType])} />
				</Box>
			) : (
				<>
					<AccordionButton>
						<Box flex="1" textAlign="left">
							{errorType}
						</Box>
						<Tag justifyContent="center" {...getTagProps(errorType, errorCounts[errorType])} />
						<AccordionIcon />
					</AccordionButton>
					<AccordionPanel pb={4}>{renderAccessibilityErrors(errorType)}</AccordionPanel>
				</>
			)}
		</AccordionItem>
	);
};

const AccessibilityList = () => {
	const { accessibilityErrors } = useContentStore();

	const errorCounts = useMemo(
		() => ({
			[ACCESSIBILITY_ERROR_CATEGORIES.ALT_TEXT]: accessibilityErrors?.filter(
				(error: AccessibilityError) => error.errorType === ACCESSIBILITY_ERROR_CATEGORIES.ALT_TEXT,
			).length,
			[ACCESSIBILITY_ERROR_CATEGORIES.LABEL]: accessibilityErrors?.filter(
				(error: AccessibilityError) => error.errorType === ACCESSIBILITY_ERROR_CATEGORIES.LABEL,
			).length,
			[ACCESSIBILITY_ERROR_CATEGORIES.CONTRAST]: accessibilityErrors?.filter(
				(error: AccessibilityError) => error.errorType === ACCESSIBILITY_ERROR_CATEGORIES.CONTRAST,
			).length,
		}),
		[accessibilityErrors],
	);

	const renderAccessibilityErrors = (errorType: string) => {
		if (!accessibilityErrors) return;

		const errorMessages = {
			[ACCESSIBILITY_ERROR_CATEGORIES.ALT_TEXT]: 'alt text is missing from image',
			[ACCESSIBILITY_ERROR_CATEGORIES.LABEL]: 'label missing from field',
			[ACCESSIBILITY_ERROR_CATEGORIES.CONTRAST]: "low contrast of element's text",
		};

		return accessibilityErrors
			.filter((error: AccessibilityError) => error.errorType === errorType)
			.map((error: AccessibilityError) => {
				const scrollTo = () => {
					scrollToElementById(error.id);
				};

				return (
					<Box key={error.id} display="flex" justifyContent="space-between" alignItems="center" mb={2}>
						<Box display="flex" alignItems="center">
							<Text fontWeight="bold" mr={2}>
								Page {error.page + 1}:
							</Text>
							<Text color="orange.500" mr={2}>
								{errorMessages[errorType]}
							</Text>
						</Box>
						<IconButton variant="ghost" icon={<Icon path={mdiTarget} />} size="sm" aria-label={'Copy to clipboard'} onClick={scrollTo} />
					</Box>
				);
			});
	};

	return (
		<Box position="relative" display="flex" alignItems="center">
			<Popover>
				<PopoverTrigger>
					<Box>
						<Tooltip label="Accessibility">
							<IconButton
								variant="ghost"
								size="sm"
								aria-label="Accessibility"
								icon={<Icon path={mdiHuman} />}
								data-testid="accessibility-list-open-btn"
							/>
						</Tooltip>
						<Box
							hidden={accessibilityErrors?.length === 0}
							borderRadius="full"
							bgColor="purple"
							color="chakra-inverse-text"
							fontSize="xs"
							fontWeight="medium"
							position="absolute"
							h={3.5}
							w={3.5}
							top={-1}
							right={-1}
							display="flex"
							alignItems="center"
							justifyContent="center"
							lineHeight="1px"
						>
							{accessibilityErrors?.length}
						</Box>
					</Box>
				</PopoverTrigger>
				<PopoverContent p={4} boxShadow="lg" mt={2} w={400}>
					<PopoverArrow />
					<Box width={'98%'} display={'flex'} alignItems={'center'} alignContent={'center'} justifyContent={'space-between'}>
						<PopoverHeader border={0} fontSize={18} fontWeight={'bold'}>
							Accessibility
						</PopoverHeader>
						<PopoverCloseButton position={'unset'} />
					</Box>
					<PopoverBody>
						<Accordion allowToggle>
							<ErrorIndicatorWithAccordion
								errorType={ACCESSIBILITY_ERROR_CATEGORIES.ALT_TEXT}
								errorCounts={errorCounts}
								renderAccessibilityErrors={renderAccessibilityErrors}
							/>

							<ErrorIndicatorWithAccordion
								errorType={ACCESSIBILITY_ERROR_CATEGORIES.LABEL}
								errorCounts={errorCounts}
								renderAccessibilityErrors={renderAccessibilityErrors}
							/>

							<ErrorIndicatorWithAccordion
								errorType={ACCESSIBILITY_ERROR_CATEGORIES.CONTRAST}
								errorCounts={errorCounts}
								renderAccessibilityErrors={renderAccessibilityErrors}
							/>
						</Accordion>
					</PopoverBody>
				</PopoverContent>
			</Popover>
		</Box>
	);
};

export default AccessibilityList;
